<template>
  <div
    class="tab-pane fade"
    id="product-setting"
    role="tabpanel"
    aria-labelledby="product-setting-tab"
  >
    <WeCard>
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0">Ürün Ayarları</h5>
        </div>
        <div class="col-auto">
          <!-- Submit -->
          <div class="text-center">
            <span class="btn btn-success" v-on:click="onSave"
              ><i class="fas fa-save"></i> Kaydet</span
            >
          </div>
          <!-- ./Submit -->
        </div>
      </div>
      <!-- ./Card Title -->
      <hr />
      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Advice -->
          <WeSettingCard
            name="product-advice"
            title="Tavsiye Ürün"
            description="Tavsiye edilen ürünleri göster"
            v-model="data.advice"
          />
          <!-- Advice -->
        </div>
        <div class="col-12 col-lg-6">
          <!-- Watermark -->
          <WeSettingCard
            name="product-watermark"
            title="Filigran"
            description="Ürünlere filigran uygula"
            v-model="data.advice"
          />
          <!-- Watermark -->
        </div>
      </div>
    </WeCard>
  </div>
</template>
<script>
export default {
  name: "Product",
  data() {
    return {
      data: {
        advice: this.settings["product.advice"] == "1" ? 1 : 0,
        watermark: this.settings["product.watermark"] == "1" ? 1 : 0,
      },
    };
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSave() {
      this.settings["product.advice"] = this.data.advice;
      this.settings["product.watermark"] = this.data.watermark;
      this.$parent.$emit("on-save", this.settings);
    },
  },
};
</script>
